import React, { useState } from "react";
import Logo from "../static/LogoGT.svg";
import Phone from "../static/Phone.svg";
import YT from "../static/Youtube.svg";
import Vk from "../static/VK.svg";
import TG from "../static/Telegram.svg";

import { Link } from "react-router-dom";
import "./app-header.css";
import { Row, Col, Menu, Typography, Input, Space, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import AppSearch from "../app-search";
import CallBackButton from "../call-back-button/call-back-button";

function AppHeader() {
  const navigate = useNavigate();

  const onMenuClick = (item) => {
    navigate(`/${item.key}`);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      {window.innerWidth < 450 ? (
        <div className="header content">
          <Row>
            <Col
              span={7}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyItems: "center",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" width={"100%"} />
              </Link>
            </Col>

            <Col span={1}></Col>

            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  className="header__phone"
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <AppSearch />
                </Col>
              </Row>
            </Col>

            <Col span={1}></Col>

            <Col
              span={3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                onClick={showDrawer}
                className="button__menu"
                icon={<MenuOutlined />}
              />
            </Col>
            <Drawer
              title="Меню"
              placement="right"
              closable={false}
              onClose={onClose}
              open={visible}
              //   visible={visible}
            >
              <Row>
                <Menu
                  onClick={(e) => {
                    onMenuClick(e);
                    onClose();
                  }}
                  mode="vertical"
                  items={[
                    {
                      label: "Каталог",
                      key: "catalogue",
                    },
                    {
                      label: (
                        <a
                          href="http://www.daf-tlt.ru/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Сервис
                        </a>
                      ),
                      key: "",
                    },
                    {
                      label: "О компании",
                      key: "aboutus",
                    },
                    {
                      label: "Контакты",
                      key: "contacts",
                    },
                  ]}
                />
                <CallBackButton />
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    minWidth: "220px",
                  }}
                  className={"footer__phone"}
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="footer-call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
                <Col
                  span={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "100%",
                    flex: "none",
                  }}
                  className={"socsety_mobile"}
                >
                  <Space>
                    <Link
                      to="https://www.youtube.com/@General_Trucks"
                      target="_blank"
                    >
                      <img src={YT} alt="" className="icon" />
                    </Link>
                    <Link to="https://vk.com/general_trucks" target="_blank">
                      <img src={Vk} alt="" className="icon" />
                    </Link>
                    <Link to="https://t.me/general_trucks" target="_blank">
                      <img src={TG} alt="" className="icon" />
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Drawer>
          </Row>
        </div>
      ) : window.innerWidth < 900 ? (
        <div className="header content">
          <Row>
            <Col
              span={6}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyItems: "center",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" width={"100%"} />
              </Link>
            </Col>

            <Col span={1}></Col>

            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  className="header__phone"
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <AppSearch />
                </Col>
              </Row>
            </Col>

            <Col span={1}></Col>

            <Col
              span={3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                onClick={showDrawer}
                className="button__menu"
                icon={<MenuOutlined />}
              />
            </Col>
            <Drawer
              title="Меню"
              placement="right"
              closable={false}
              onClose={onClose}
              open={visible}
              //   visible={visible}
            >
              <Row>
                <Menu
                  onClick={(e) => {
                    onMenuClick(e);
                    onClose();
                  }}
                  mode="vertical"
                  items={[
                    {
                      label: "Каталог",
                      key: "catalogue",
                    },
                    {
                      label: (
                        <a
                          href="http://www.daf-tlt.ru/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Сервис
                        </a>
                      ),
                      key: "",
                    },
                    {
                      label: "О компании",
                      key: "aboutus",
                    },
                    {
                      label: "Контакты",
                      key: "contacts",
                    },
                  ]}
                />
                <CallBackButton />
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    minWidth: "220px",
                  }}
                  className={"footer__phone"}
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="footer-call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
                <Col
                  span={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "100%",
                    flex: "none",
                  }}
                  className={"socsety_mobile"}
                >
                  <Space>
                    <Link
                      to="https://www.youtube.com/@General_Trucks"
                      target="_blank"
                    >
                      <img src={YT} alt="" className="icon" />
                    </Link>
                    <Link to="https://vk.com/general_trucks" target="_blank">
                      <img src={Vk} alt="" className="icon" />
                    </Link>
                    <Link to="https://t.me/general_trucks" target="_blank">
                      <img src={TG} alt="" className="icon" />
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Drawer>
          </Row>
        </div>
      ) : window.innerWidth < 1350 ? (
        <div className="header content">
          <Row>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyItems: "center",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" width={"100%"} />
              </Link>
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <AppSearch />
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
            <Col
              span={5}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Space>
                <img src={Phone} alt="" width={35} />
                <Typography className="call-now">
                  <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                </Typography>
              </Space>
            </Col>
            <Col span={1}></Col>
            <Col
              span={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={showDrawer}
                className="button__menu"
                icon={<MenuOutlined />}
              />
            </Col>
            <Drawer
              title="Меню"
              placement="right"
              closable={false}
              onClose={onClose}
              open={visible}
              //   visible={visible}
            >
              <Menu
                onClick={(e) => {
                  onMenuClick(e);
                  onClose();
                }}
                mode="vertical"
                items={[
                  {
                    label: "Каталог",
                    key: "catalogue",
                  },
                  {
                    label: (
                      <a
                        href="http://www.daf-tlt.ru/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Сервис
                      </a>
                    ),
                    key: "link",
                  },
                  {
                    label: "О компании",
                    key: "aboutus",
                  },
                  {
                    label: "Контакты",
                    key: "contacts",
                  },
                ]}
              />
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    minWidth: "220px",
                  }}
                  className={"footer__phone"}
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="footer-call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
                <Col
                  span={10}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "100%",
                    flex: "none",
                  }}
                >
                  <Space>
                    <Link
                      to="https://www.youtube.com/@General_Trucks"
                      target="_blank"
                    >
                      <img src={YT} alt="" className="icon" />
                    </Link>
                    <Link to="https://vk.com/general_trucks" target="_blank">
                      <img src={Vk} alt="" className="icon" />
                    </Link>
                    <Link to="https://t.me/general_trucks" target="_blank">
                      <img src={TG} alt="" className="icon" />
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Drawer>
          </Row>
        </div>
      ) : (
        <div className="header content">
          <Row>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyItems: "center",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" width={"100%"} />
              </Link>
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Menu
                    onClick={onMenuClick}
                    className="main-menu"
                    mode="horizontal"
                    items={[
                      {
                        label: "Каталог",
                        key: "catalogue",
                      },
                      {
                        label: (
                          <a
                            href="http://www.daf-tlt.ru/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Сервис
                          </a>
                        ),
                        key: "link",
                      },
                      {
                        label: "О компании",
                        key: "aboutus",
                      },
                      {
                        label: "Контакты",
                        key: "contacts",
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <AppSearch />
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              }}
              className="call-back-block"
            >
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CallBackButton />
                {/* <Button className='call-back-button'><a href="#feedback1">заказать звонок</a></Button> */}
              </Col>
            </Col>
            <Col span={1}></Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Space>
                <img src={Phone} alt="" width={35} />
                <Typography className="call-now">
                  <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                </Typography>
              </Space>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
export default AppHeader;
