import { Col, Row, Image } from "antd";

import "./app-clients.css";

import client_01 from "./static/client_01.webp";
import client_02 from "./static/client_02.webp";
import client_03 from "./static/client_03.webp";
import client_04 from "./static/client_04.webp";
import client_05 from "./static/client_05.webp";
import client_06 from "./static/client_06.webp";
import client_07 from "./static/client_07.webp";
import client_08 from "./static/client_08.webp";

function AppClients() {
  const clients = [
    client_01,
    client_02,
    client_03,
    client_04,
    client_05,
    client_06,
    client_07,
    client_08,
  ];

  return (
    <>
      {window.innerWidth < 650 ? (
        <div className="horizontal-gallery clients">
          <Row className="">
            {clients.map((item) => (
              <Col className="client-image" key={item}>
                <Image src={item} alt={item} loading="lazy" />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <Row className="clients">
          {clients.map((item) => (
            <Col className="client-image" key={item}>
              <Image src={item} alt={item} loading="lazy" />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

export default AppClients;
