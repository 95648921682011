import React, { Component } from "react";
import { Row, Col, Typography } from "antd";
import { ArmorService } from "../../service";
import "./feedback.css";
import FeedbackForm from "./feedback-form";

class Feedback2 extends Component {
  api = new ArmorService();
  constructor(props) {
    super(props);
    this.state = {
      nameUser: "",
      phone: "",
    };
  }
  onFinish = (value) => {
    this.api.sendRequest({
      phone: this.state.phone,
      name: this.state.nameUser,
      status: "NEW",
    });
    this.setState({
      nameUser: "",
      phone: "",
    });
  };

  handleNameUserChange = (event) => {
    console.log("handleNameUserChange", event.target.value);
    this.setState({ nameUser: event.target.value });
  };

  handlePhoneChange = (event) => {
    console.log("handlePhoneChange", event.target.value);
    this.setState({ phone: event.target.value });
  };

  render() {
    return (
      <Row
        className="feedback content"
        id="feedback"
        justify={"space-between"}
        align={"middle"}
      >
        <Col span={10} className="feedback__text">
          <Typography.Text>
            Напишите свои контакты
            <br /> и мы
            <span style={{ color: "#F67828" }}> вам перезвоним </span>
          </Typography.Text>
        </Col>
        {window.innerWidth < 750 ? (
          <Col span={12} className="feedback__form-style">
            <FeedbackForm />
          </Col>
        ) : (
          <Col span={7} className="feedback__form-style">
            <FeedbackForm />
          </Col>
        )}
      </Row>
    );
  }
}
export default Feedback2;
