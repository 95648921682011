import * as React from "react";
import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useRouteError,
} from "react-router-dom";
import MainPage from "../pages/main-page";
import AppLayout from "../app-layout";
import NotFoundPage from "../not-found-page/not-found-page";
import AppSpinner from "../app-spinner";

const ProductPage = lazy(() => import("../pages/product-page"));
const CategoriesPage = lazy(() => import("../pages/categories-page"));
const ContactsPage = lazy(() => import("../pages/contacts-page"));
const PolicyPage = lazy(() => import("../pages/policy-page"));
const AboutCompanyPage = lazy(() => import("../pages/about-company-page"));

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return (
    <div>
      <NotFoundPage />
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AppLayout />} errorElement={<ErrorBoundary />}>
        <Route index element={<MainPage />} />
        <Route
          path="catalogue/"
          exact
          element={
            <Suspense fallback={<AppSpinner />}>
              <CategoriesPage />
            </Suspense>
          }
        />
        <Route
          path="product/:title_id"
          element={
            <Suspense fallback={<AppSpinner />}>
              <ProductPage />
            </Suspense>
          }
        />
        <Route
          path="contacts"
          element={
            <Suspense fallback={<AppSpinner />}>
              <ContactsPage />
            </Suspense>
          }
        />
        <Route
          path="personal_information"
          element={
            <Suspense fallback={<AppSpinner />}>
              <PolicyPage />
            </Suspense>
          }
        />
        <Route
          path="aboutus"
          element={
            <Suspense fallback={<AppSpinner />}>
              <AboutCompanyPage />
            </Suspense>
          }
        />
        <Route path="link" element={<MainPage />} />
      </Route>
    </>
  )
);

const App = () => <RouterProvider router={router} />;

export default App;
