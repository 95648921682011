import { Card, Col, Row, Button } from "antd";
import { Link } from "react-router-dom";

import "./app-main-info.css";

import AppCards from "../app-cards/app-cards";
import Feedback1 from "../feedback/feedback1";
import Feedback2 from "../feedback/feedback2";
import AppClients from "../app-clients/app-clients";
import AppMap from "../app-map/app-map";
import AppReviews from "../app-reviews/app-reviews";
import AppTitle from "../app-title/app-title";

//#region AppMainAdvantages
const advantages = [
  {
    id: 1,
    title: `<span style='color: #F67828;'>Большой выбор</span> европейских авто и полуприцепов в наличии и под заказ`,
    text: "В наличии грузовики марки DAF, Volvo, Scania, а также прицепы Schmitz и Krone. Привезём технику под заказ по вашим потребностям",
  },
  {
    id: 2,
    title: `Машины с <span style='color: #F67828;'>полным пакетом</span> документов`,
    text: "Все авто прошли таможенное оформление, уплачены пошлины, установлены системы ЭРА-Глонасс и получены ЭПТС со статусом действующий",
  },
  {
    id: 3,
    title: `<span style='color: #F67828;'>5 центров</span> по России`,
    text: "Удобное расположение в Тольятти, Казань, Набережные челны, Уфа и Екатеринбург",
  },
  {
    id: 4,
    title: `Приобретение в <span style='color: #F67828;'>лизинг</span>`,
    text: "Работаем с любой лизинговой компанией. Предлагаем выгодные расчёты от Альфа лизинг.",
  },
];

const aboutCompany = [
  "Компания General Trucks – ваш надежный партнер по продаже техники для грузоперевозок!",
  "Компания успешно работает с 2018 года и специализируется на предоставлении качественных грузовиков марок DAF, Scania и Volvo – как новых, так и с пробегом. Мы также предлагаем полуприцепы Schmitz и Krone.",
  "Наши 5 центров оборудованы современными сервисными установками и запчастями высокого качества. Наша команда экспертов обеспечит надежное и профессиональное обслуживание вашего транспорта.",
  "Если вы ищете надежного партнера для приобретения техники для грузоперевозок и получения качественного сервиса, обращайтесь к нам. ‌General Trucks готов предложить вам лучшие решения грузовой техники.",
];

function AppMainAdvantages() {
  return (
    <div className="main__avantages content">
      {advantages.map((item) => {
        return (
          <Col key={item.id} span={11} style={{ padding: 0 }}>
            <Card>
              <h3
                className="title__avantages"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <p className="text__avantages">{item.text}</p>
            </Card>
          </Col>
        );
      })}
    </div>
  );
}

function AppMainAdvantagesOther() {
  return (
    <div className="main__avantages">
      {aboutCompany.map((item) => {
        return (
          <p className="other__avantages" key={item}>
            {item}
          </p>
        );
      })}
    </div>
  );
}
//#endregion

//#region AppAnswersQuestions
const answers = [
  {
    answer: "Что входит в стоимость авто?",
    question:
      "<p>Вы приобретаете у нас технику со всем пакетом документов!<br />В стоимость входит, цена на автомобиль, все авто проходят таможенное оформление, уплачены пошлины, установлены системы ЭРА-Глонасс<br />и получены ЭПТС со статусом действующий</p>",
  },
  {
    answer: "Можно у вас привезти авто под заказ?",
    question: `<p>Да, позвоните по телефону <a href='tel:+7 8482 43 55 50'>+7 8482 43 55 50</a> и наш менеджер подберёт<br />технику по вашим характеристикам и ответит на все вопросы по заказу</p>`,
  },
  {
    answer: "Могу ли я купить в лизинг?",
    question: `<p>Да, мы работаем с лизинговыми компаниями, преимущественно с Альфа лизингом.<br />Для уточнения работы с вашей лизинговой компанией, позвоните по телефону <a href='tel:8 8482 43 55 50'>8 8482 43 55 50</a></p>`,
  },
  {
    answer: "Могу я у вас купить запчасти?",
    question: `<p>У нас есть все запчасти на DAF, для уточнения наличия или по срокам поставки, по телефону <a href='tel:8 8482 611 550'>8 8482 611 550</a></p>`,
  },
  {
    answer: "Как записаться на сервис?",
    question: `<p>Для этого достаточно позвонить по телефону:<br />Тольятти (с. Зелёновка) <a href='tel:+7 (8482) 611 550'>+7 (8482) 611 550</a><br />Казань <a href='tel:+7 (843) 239-05-50'>+7 (843) 239-05-50</a><br />Набережные челны <a href='tel:+7 (8552) 784-550'>+7 (8552) 784-550</a><br />Екатеринбург <a href='tel:+7 (8482) 622-550'>+7 (8482) 622-550</a><br />Уфа <a href='tel:+7 (347) 262-77-55'>+7 (347) 262-77-55</a></p>`,
  },
];

function AppAnswersQuestions() {
  return (
    <div className="accordions content">
      {answers.map((item) => (
        <div key={item.answer}>
          <button onClick={(e) => questions(e.target)} className="accordion">
            {item.answer}
          </button>
          <div className="panel">
            <div dangerouslySetInnerHTML={{ __html: item.question }} />
          </div>
        </div>
      ))}
    </div>
  );
}

function questions(item) {
  item.classList.toggle("active");
  const panel = item.nextElementSibling;
  panel.style.maxHeight
    ? (panel.style.maxHeight = null)
    : (panel.style.maxHeight = panel.scrollHeight + "px");
}

//#endregion

function AppMainInfo() {
  return (
    <div className="main">
      <AppTitle text={"Авто в наличии"} />
      <Row className="content">
        <AppCards countCards={8} groupsID={[114, 123]} />
        <AppCards countCards={4} groupsID={[117]} />
        <AppCards countCards={4} groupsID={[130]} />
        <AppCards countCards={4} groupsID={[129]} />
      </Row>
      <Row className="content">
        <Col className="button-all-goods">
          <Button>
            <Link to="/catalogue">Посмотреть&nbsp;ещё</Link>
          </Button>
        </Col>
      </Row>

      <Row>
        <Feedback1 />
      </Row>

      <AppTitle text={"Наши центры"} />
      <AppMap />

      <AppTitle text={"Преимущества"} />
      <Row gutter={40} style={{ margin: 0, display: "block" }}>
        <AppMainAdvantages />
      </Row>
      <Row style={{ margin: 0 }} className="video__avantages content">
        <Col span={12} style={{ padding: 0 }}>
          <AppMainAdvantagesOther />
        </Col>
        <Col span={11} style={{ padding: 0 }}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/kMsxYbADIn8"
            title="Партнерство, которое приносит Вам прибыль в грузоперевозках❗"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>

      <AppTitle text={"Наши клиенты"} />
      <Row>
        <AppClients />
      </Row>

      <AppTitle text={"Популярные модели"} />
      <Row className="content">
        <AppCards countCards={4} groupsID={[114, 123]} />
      </Row>
      <Row className="content">
        <Col className="button-all-goods">
          <Button>
            <Link to="/catalogue">Посмотреть&nbsp;ещё</Link>
          </Button>
        </Col>
      </Row>

      <AppTitle text={"Отзывы"} />
      <AppReviews />

      <AppTitle text={"Отвечаем на вопросы"} />
      <Row
        style={{ backgroundColor: "#fff", marginTop: "20px", display: "block" }}
      >
        <AppAnswersQuestions key="AnswersQuestions" />
      </Row>
      <Row>
        <Feedback2 />
      </Row>
    </div>
  );
}

export default AppMainInfo;
