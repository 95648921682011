import React from "react";
import Logo from "../static/LogoGT.svg";
import Phone from "../static/Phone.svg";
import YT from "../static/Youtube.svg";
import Vk from "../static/VK.svg";
import TG from "../static/Telegram.svg";
import { Link } from "react-router-dom";
import "./app-footer.css";
import { Row, Col, Menu, Typography, Space } from "antd";
import { useNavigate } from "react-router-dom";

function AppFooter() {
  const navigate = useNavigate();

  const onMenuClick = (item) => {
    navigate(`/${item.key}`);
  };

  return (
    <>
      {window.innerWidth < 950 ? (
        <div className="footer" style={{ height: "100%" }}>
          <Row justify="center" style={{ height: "100%", padding: "25px" }}>
            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  minWidth: "220px",
                }}
                className={"footer__phone"}
              >
                <Space>
                  <img src={Phone} alt="" width={35} />
                  <Typography className="footer-call-now">
                    <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                  </Typography>
                </Space>
              </Col>
            </Row>
            <Row className="footer-menu">
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Menu
                  onClick={onMenuClick}
                  items={[
                    {
                      label: "Каталог",
                      key: "catalogue",
                    },
                    {
                      label: (
                        <a
                          href="http://www.daf-tlt.ru/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Сервис
                        </a>
                      ),
                      key: "link",
                    },
                  ]}
                />
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Menu
                  onClick={onMenuClick}
                  items={[
                    {
                      label: "О компании",
                      key: "aboutus",
                    },
                    {
                      label: "Контакты",
                      key: "contacts",
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col className="policy" span={24} style={{ display: "flex" }}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "300",
                    color: "#fff",
                    opacity: "0.5",
                  }}
                >
                  <Link to="personal_information">
                    Политика конфеденциальности
                  </Link>
                </Typography>
              </Col>
            </Row>
            <Row className="footer_logo_socsety">
              <Col
                span={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Link to="/">
                  <img src={Logo} alt="" width={"100%"} />
                </Link>
              </Col>
              <Col span={6}></Col>
              <Col
                span={10}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Space>
                  <Link
                    to="https://www.youtube.com/@General_Trucks"
                    target="_blank"
                  >
                    <img src={YT} alt="" className="icon" />
                  </Link>
                  <Link to="https://vk.com/general_trucks" target="_blank">
                    <img src={Vk} alt="" className="icon" />
                  </Link>
                  <Link to="https://t.me/general_trucks" target="_blank">
                    <img src={TG} alt="" className="icon" />
                  </Link>
                </Space>
              </Col>
            </Row>
          </Row>
        </div>
      ) : (
        <div className="footer" style={{ height: "100%" }}>
          <Row justify="center" style={{ height: "100%", padding: "25px" }}>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyItems: "center",
              }}
            >
              <Link to="/">
                <img src={Logo} alt="" width={"100%"} />
              </Link>
            </Col>
            <Col span={1}></Col>
            <Col
              span={13}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Row className="footer-menu">
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Menu
                    onClick={onMenuClick}
                    mode="horizontal"
                    items={[
                      {
                        label: "Каталог",
                        key: "",
                        key: "catalogue",
                      },
                      {
                        label: (
                          <a
                            href="http://www.daf-tlt.ru/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Сервис
                          </a>
                        ),
                        key: "link",
                      },
                      {
                        label: "О компании",
                        key: "aboutus",
                      },
                      // {
                      //   label: "Вакансии",
                      //   key: "",
                      //   // key: "vacancies"
                      // },
                      {
                        label: "Контакты",
                        key: "contacts",
                      },
                      // {
                      //   label: "Статьи",
                      //   key: "",
                      //   // key: "articles"
                      // },
                    ]}
                  ></Menu>
                </Col>
              </Row>
              <Row className="footer_logo_socsety">
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    className="policy"
                    style={{
                      fontSize: "24px",
                      fontWeight: "300",
                      color: "#fff",
                      opacity: "0.5",
                    }}
                  >
                    <Link to="personal_information">
                      Политика конфеденциальности
                    </Link>
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
            <Col
              span={5}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    minWidth: "220px",
                  }}
                  className={"footer__phone"}
                >
                  <Space>
                    <img src={Phone} alt="" width={35} />
                    <Typography className="footer-call-now">
                      <a href="tel:+7 8482 43 55 50">8 8482 43 55 50</a>
                    </Typography>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Space>
                    <Link
                      to="https://www.youtube.com/@General_Trucks"
                      target="_blank"
                    >
                      <img src={YT} alt="" className="icon" />
                    </Link>
                    <Link to="https://vk.com/general_trucks" target="_blank">
                      <img src={Vk} alt="" className="icon" />
                    </Link>
                    <Link to="https://t.me/general_trucks" target="_blank">
                      <img src={TG} alt="" className="icon" />
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default AppFooter;
