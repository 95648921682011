import { Col, Row } from "antd";
import AppCarousel from "../app-carousel/app-carousel";
import AppMainInfo from "../app-main-info";

export default function MainPage() {
  return (
    <>
      <title>Тягачи, полуприцепы и легковые авто</title>
      <meta
        name="description"
        content="Широкий выбор тягачей, полуприцепов и легковых авто. Сервисное обслуживание DAF в Тольятти, Казани, Набережных Челнах, Уфе и Екатеринбурге"
      />
      <Row>
        <Col span={24}>
          <AppCarousel />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AppMainInfo />
        </Col>
      </Row>
    </>
  );
}
