import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout, Space, FloatButton } from "antd";
import AppHeader from "../app-header";
import AppFooter from "../app-footer";
import "./app-layout.css";
import BackTopBtn from "../static/backtop.svg";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 1000);
  }, [pathname]);

  return null;
}

const { Header, Footer, Content } = Layout;

const IconBackTopBtn = () => (
  <div>
    <img src={BackTopBtn} alt="" width={"100%"} />
  </div>
);

const AppLayout = () => (
  <>
    <ScrollToTop />
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      size={[0, 48]}
    >
      <Layout
        style={{
          backgroundColor: "#36322F",
        }}
      >
        <Header
          style={{
            backgroundColor: "#36322F",
            height: "auto",
            padding: 0,
          }}
        >
          <AppHeader />
        </Header>
        <Content>
          <Outlet />
          <FloatButton.BackTop
            className="back-top-btn"
            icon={<IconBackTopBtn />}
          />
        </Content>
        <Footer
          style={{
            backgroundColor: "#36322F",
            height: "222px",
          }}
        >
          <AppFooter />
        </Footer>
      </Layout>
    </Space>
  </>
);
export default AppLayout;
